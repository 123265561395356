import { useState, useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { useDeleteSessionMutation } from "@RHCommerceDev/hooks-mutations";
import yn from "yn";
import useStytch from "@RHCommerceDev/hooks/useStytch";
import { getBrandedUrl } from "utils/getBrandedUrl";
import useBrand from "@RHCommerceDev/hooks-use-brand/useBrand";

export const useAuth = () => {
  const env = useEnv();
  const FEATURE_STYTCH = yn(env?.FEATURE_STYTCH);

  // move loading to useSession after remove fetch request in signIn
  const [loading, setLoading] = useState(false);
  const { language } = useUserPreferences();
  const [signedOut, setSignedOut] = useState(false);
  const [error, setError] = useState(false);
  const [deleteSession] = useDeleteSessionMutation();
  const { setStorageValueWrapper } = useCookiesWithPermission();
  // const clientUrl = getClientOrigin() ?? "";
  const brand = useBrand();

  let locale = language?.split("-")?.[0] || "en";

  const { keycloak } = useKeycloak();

  const stytch = useStytch();

  const {
    REACT_APP_KEYCLOAK_ORIGIN,
    REACT_APP_KEYCLOAK_PATH,
    REACT_APP_KEYCLOAK_REALM,
    REACT_APP_KEYCLOAK_CLIENTID
  } = useEnv();

  const signOut = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      // -- TODO: Remove fetch after SessionProvider is fully released
      if (FEATURE_STYTCH) {
        console.warn("Using Stytch for sign-out");

        console.warn("Brand determined:", brand);
        await stytch?.session?.revoke();
        console.warn("Stytch session revoked");
        await deleteSession();
        setSignedOut(true);
      } else {
        const response = await fetch(
          `${REACT_APP_KEYCLOAK_ORIGIN}${REACT_APP_KEYCLOAK_PATH}/realms/${REACT_APP_KEYCLOAK_REALM}/rhlogout`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              Authorization: `Bearer ${keycloak?.token}`,
              Accept: "application/json"
            },
            body: null
          }
        );

        if (!response.ok) throw new Error();
      }

      setSignedOut(true);
      localStorage.removeItem("isLoggedin");
      console.warn("Logged out and localStorage cleared");
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
      console.warn("Sign-out process finished");

      // Log the current URL after sign-out is completed
      const currentUrl = window.location.href;
      console.warn("Current URL after sign-out:", currentUrl);

      if (!processEnvServer && !FEATURE_STYTCH) {
        const url = new URL(window?.location?.href);
        if (url?.pathname?.includes("shopping_cart.jsp")) {
          window.location.href = url.origin;
        }
        console.warn("Remaining on the same page after sign-out.");
        if (brand === "TN" || brand === "BC") {
          const redirectUrl = getBrandedUrl("/", brand);
          console.warn("Redirect URL for brand:", redirectUrl);
          window.location.href = redirectUrl;
        }
        console.warn("Remaining on the same page after sign-out.", brand);
      }
    }
  }, [
    FEATURE_STYTCH,
    REACT_APP_KEYCLOAK_ORIGIN,
    REACT_APP_KEYCLOAK_PATH,
    REACT_APP_KEYCLOAK_REALM,
    // clientUrl,
    deleteSession,
    keycloak?.token,
    stytch?.session,
    brand
  ]);

  const getRedirectUri = useCallback((path?: string) => {
    if (!path) {
      return undefined;
    }

    const url = new URL(window.location.href);
    url.pathname = path;
    return url.href;
  }, []);

  const signIn = useCallback(
    async (path?: string) => {
      if (!processEnvServer) {
        setStorageValueWrapper({
          storageKey: "REACT_APP_KEYCLOAK_CLIENTID",
          value: REACT_APP_KEYCLOAK_CLIENTID
        });
      }

      const redirectUri = getRedirectUri(path);
      keycloak.clientId = REACT_APP_KEYCLOAK_CLIENTID;
      keycloak.login({ redirectUri, locale });
      setSignedOut(false);
    },
    [setStorageValueWrapper]
  );

  return {
    loading,
    error,
    signOut,
    signIn,
    signedOut
  };
};
