import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { countries } from "@RHCommerceDev/resources/countries-config.json";
import { isGDPRCompliantORCookieAccepted } from "@RHCommerceDev/utils/analytics/utils";
import { COOKIE_WITH_SESSION } from "@RHCommerceDev/utils/constants";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { useCallback, useMemo } from "react";
import yn from "yn";
import { EmailPopUp } from "./types";

function useCheckEmailPopUp(): EmailPopUp {
  const env = useEnv();
  const country = getCountryFromUrl();
  const { userType } = useRhUserAtomValue();

  //* this useMemo return true or false to render email popup based on flag or cookie.
  const renderEmailSignup =
    !["contract", "trade"].includes(userType?.toLowerCase() || "") &&
    ((yn(env?.FEATURE_EMAIL_MARKETING_POPUP_NA) &&
      !countries[country].emailPopup.value) ||
      countries[country].emailPopup.value);

  //** here we are getting saved cookie and check to show email pop
  const openEmailPopUpWithSessionCheck = useMemo(() => {
    return (
      !processEnvServer &&
      !!JSON.parse(JSON.stringify(sessionStorage.getItem(COOKIE_WITH_SESSION)))
        ?.isPopClosed
    );
  }, []);

  //return COOKIE_WITH_SESSION value from session

  let emailPopupData =
    !processEnvServer &&
    JSON.parse(
      sessionStorage.getItem(COOKIE_WITH_SESSION)
        ? sessionStorage.getItem(COOKIE_WITH_SESSION)
        : null
    );

  //* this method used to set cookie
  const setSessionCookie = useCallback(
    (key: string, value: any) => {
      if (isGDPRCompliantORCookieAccepted()) {
        sessionStorage.setItem(key, JSON.stringify(value));
      }
    },
    [isGDPRCompliantORCookieAccepted]
  );

  const resetCookieClicked = useCallback(() => {
    setSessionCookie?.(COOKIE_WITH_SESSION, {
      ...emailPopupData,
      clickCount: 1,
      isPopClosed: true
    });
  }, [emailPopupData, setSessionCookie]);

  return {
    renderEmailSignup,
    isClosed: openEmailPopUpWithSessionCheck,
    setSessionCookie,
    emailPopupData,
    resetCookieClicked
  };
}

export default useCheckEmailPopUp;
