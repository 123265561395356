import { useMutation } from "@apollo/client";
import { deleteSessionMutation } from "@RHCommerceDev/graphql-client/queries/session";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { useCallback } from "react";
import { getGlobal } from "utils/Globals/getGlobal";
import yn from "yn";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";

const env = getGlobal("_env");
const FEATURE_STYTCH = yn(env?.FEATURE_STYTCH);

export const useDeleteSessionMutation = () => {
  const { keycloak } = useKeycloak();
  const country = getCountryFromUrl();
  const language = getLanguageFromUrl();

  const stytchLogout = useCallback(() => {
    localStorage.removeItem("isNewUser");
    localStorage.removeItem("isLoggedin");
    if (!processEnvServer && env.REACT_APP_BFF_ORIGIN) {
      window.location.href = `${
        env.REACT_APP_BFF_ORIGIN
      }/${country.toLocaleLowerCase()}/${language.raw}`;
    }
  }, [country, language]);

  return useMutation<Mutation>(deleteSessionMutation, {
    variables: {
      country
    },
    context: {
      fetchOptions: {
        method: "POST"
      }
    },
    onError() {
      FEATURE_STYTCH ? stytchLogout() : keycloak?.logout();
    },
    onCompleted() {
      FEATURE_STYTCH ? stytchLogout() : keycloak?.logout();
    }
  });
};

export default useDeleteSessionMutation;
